import * as React from "react";
import * as ReactDOM from "react-dom";

// @ts-ignore
window.FullCalendarVDom = {
    Component: React.Component,
    createElement: React.createElement,
    render: ReactDOM.render,
    createRef: React.createRef,
    Fragment: React.Fragment,
    createContext: React.createContext,
    flushToDom,
    unmountComponentAtNode: ReactDOM.unmountComponentAtNode
};

export function flushToDom()
{

}

// @ts-ignore
if ( !window.FullCalendarVDom ) {
    console.error( 'vdom was not set on the window!', window );
}
