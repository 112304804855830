import * as React from "react";
import {BaseCalendar} from "./components/calendar/calendar-base";
import {CalendarDraggableEvents} from "./components/calendar/calendar-draggable-events";
import {CalendarSelectionManager} from "./components/calendar/calendar-selection-manager";
import {useCalendar} from "./models/calendar.store";
import {observer} from "mobx-react-lite";
import {ModificationDescription} from "./components/calendar/modification/modification-description";
import {ModificationRegion} from "./components/calendar/modification/modification-region";
import {ModificationSource} from "./components/calendar/modification/modification-source";
import {TooltipComponent} from "../../components/form/tooltip.component";
import {TimeSlotType} from "./types/time-slot-type";

function eventContentComponent( store: any )
{

    return ( info: any ) => {
        let eventProperties = info.event.extendedProps;
        const sources = store.configuration.sourcesForEvent( eventProperties.sources );
        const sourceLabels = sources && Array.isArray( sources ) ? sources.map( ( s: any ) => s.label ).join( ', ' ) : '';
        const isNationalHoliday = info.event.extendedProps.type === TimeSlotType.NATIONAL_HOLIDAY;
        let region = '';
        if ( eventProperties.region ) {
            region = store.configuration.regionForEvent( eventProperties.region ).label;
        }
        else {
            if ( info.event.extendedProps.type === TimeSlotType.UNAVAILABLE ) {
                region = store.translate( 'MyIzenApplicationModule.entities.enums.TimeSlotType.UNAVAILABLE' );
            }
            else if ( isNationalHoliday ) {
                region = store.translate( 'MyIzenApplicationModule.entities.enums.TimeSlotType.NATIONAL_HOLIDAY' );
            }

            if ( region === '' ) {
                region = store.translate( 'calendar.agenda.properties.region.missing' );
            }

        }
        const description = eventProperties.description != null ? eventProperties.description : '';
        const isNotEditable = !eventProperties.editable;

        return (<React.Fragment>
            <div>
                {isNotEditable && <i className="fas fa-lock mr-2"></i>}
                {!isNationalHoliday && <b className='pr-2'>{info.timeText}</b>}
                <i>{region} {description && <TooltipComponent id={info.event.id} content={description}/>}</i>
            </div>
            <div className="d-flex flex-column text-truncate">
                {sourceLabels.length > 0 && <i title={sourceLabels}>Sources: {sourceLabels}</i>}
            </div>
        </React.Fragment>)
    };
}

// https://react-select.com/components#replaceable-components
function AgendaModifySelection( props: any )
{
    const {store} = props;
    const presentTypes = store.uniqueSelectedEventTypes();
    let formContent;
    if ( presentTypes.length === 1 ) {
        const singleType = presentTypes[0];
        if ( TimeSlotType.UNAVAILABLE === singleType ) {
            formContent = <React.Fragment>
                <ModificationDescription/>
            </React.Fragment>;
        }
        else {
            formContent = <React.Fragment>
                <ModificationSource/>
                <ModificationRegion/>
                <ModificationDescription/>
            </React.Fragment>;
        }
    }
    else {
        formContent = <React.Fragment>
            <ModificationSource/>
            <ModificationRegion/>
        </React.Fragment>;
    }

    return (<form className='modify-selection'>
        {formContent}
    </form>)
}

export const UserAgendaCalendar = observer( ( props: any ) => {
    const store = useCalendar();
    React.useEffect( () => {
        store.initialize( {modifiable: true, ...props.configuration} );
    }, [store] );

    const fetchEvents = function ( from: string, to: string ) {
        store.fetchEvents( from, to );
        return store.events.slice();
    };

    const save = () => {
        store.save();
    };
    const backToOverview = () => {
        window.location.href = store.configuration.backUrl;
    };

    const dayHeaderFormat = {
        weekday: 'long'
    };

    return (<div className='calendar-wrapper w-100 pt-3 pb-3 d-flex'>
        <div className='col-md-10'>
            <BaseCalendar initialView={'timeGridWeek'}
                          possibleViews={['timeGridWeek', 'dayGridMonth']}
                          allowPeriodSelection={true}
                          eventContentComponent={eventContentComponent( store )}
                          initialEvents={store.events.slice()}
                          fetchEvents={fetchEvents}
                          dayHeaderFormat={dayHeaderFormat}
                          modifiable={store.configuration.isModifiable()}/>
        </div>
        <div className='izen-calendar-sidebar col-md-2'>
            <div className='d-flex flex-column h-100'>
                <CalendarDraggableEvents types={props.configuration.appointmentTypes}/>
                <CalendarSelectionManager modifySelectionContent={() => <AgendaModifySelection store={store}/>}/>
            </div>
            <div className='izen-calendar-actions'>
                <a className='btn btn-link' onClick={backToOverview} href="#">
                    {store.translate( 'calendar.template.actions.back' )}
                </a>
                <button className='btn btn-primary' onClick={save} disabled={store.eventsToRemove.length === 0 && store.eventsToUpdate.length === 0}>
                    {store.translate( 'calendar.template.actions.save' )}
                </button>
            </div>
        </div>
    </div>);
} );
