import {observer} from "mobx-react-lite";
import {useCalendar} from "../../../models/calendar.store";
import {default as React, useEffect, useState} from "react";
import {ValueType} from "react-select";
import {getSnapshot} from "mobx-state-tree";
import {SelectComponent} from "../../../../../components/form/select.component";
import {unique} from "../../../../../helpers/utilities";

export const ModificationSource = observer( () => {
    const store = useCalendar();

    const [selected, setSelected] = useState<ValueType<any, true>>();

    useEffect( () => {
        const selectedEvents = store.selectedEvents();
        let sourcesToSelect;
        if ( selectedEvents.length === 1 ) {
            sourcesToSelect = getSnapshot( selectedEvents[0].extendedProps.sources );
        }
        if ( selectedEvents.length > 1 ) {
            const selectedSources = selectedEvents.flatMap( se => se.extendedProps.sources )
                .filter( unique );
            const eventWithLessOrMoreSourcesExists = selectedEvents.map( se => se.extendedProps.sources )
                .findIndex( srcs => srcs.length !== selectedSources.length ) !== -1;
            if ( !eventWithLessOrMoreSourcesExists ) {
                sourcesToSelect = selectedSources;
            }
        }
        if ( sourcesToSelect ) {
            const sourcesForEvent: any = store.configuration.sourcesForEvent( sourcesToSelect );
            setSelected( sourcesForEvent );
            store.modificationState.updateSources( sourcesForEvent );
        }
    }, [] );

    const onSelectionChange = ( selectedOptions: any ) => {
        store.modificationState.updateSources( selectedOptions );
        setSelected( selectedOptions );
    };

    return <SelectComponent label={store.translate( 'calendar.template.properties.sources.label' )}
                            selectableOptions={store.configuration.sourceOptions()}
                            selected={selected}
                            multiValue={true}
                            onChange={onSelectionChange}/>;
} );