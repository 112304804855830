import {default as React} from "react";
import {useCalendar} from "../../models/calendar.store";
import {observer} from "mobx-react-lite";

interface CalendarSelectionManagerProps
{
    modifySelectionContent?: () => React.ReactNode
}

export const CalendarSelectionManager = observer( ( props: CalendarSelectionManagerProps ) => {
    const store = useCalendar();

    const clearSelection = () => {
        store.clearSelection();
    };

    const removeSelection = () => {
        store.openModal( store.translate( 'calendar.template.modal.remove.header' ), <div>{store.translate( 'calendar.template.modal.remove.body' )}</div>,
                         () => store.removeSelection() );
    };

    const modifySelection = () => {
        store.openModal( store.translate( 'calendar.template.modal.modify.header' ), props.modifySelectionContent ? props.modifySelectionContent() : (null),
                         () => store.modifySelection() );
    };

    const selectionSize = store.selectedEvents().length === 1 ? store.selectedEvents().length + ' ' + store.translate(
        'calendar.template.selection.size.singular' ) : store.selectedEvents().length + ' ' + store.translate( 'calendar.template.selection.size.plural' );

    return <div className='izen-calendar-selection'>
        <h4> {store.translate( 'calendar.template.selection.header' )}
            <small className='pl-2' onClick={clearSelection}>(<a className='btn-link' href="#">
                {store.translate( 'calendar.template.selection.actions.clear' )}
            </a>)
            </small>
        </h4>
        <div className='mb-2'>{selectionSize}</div>
        <div className='izen-calendar-selection-actions d-flex flex-row justify-content-around'>
            <button className='btn btn-danger mr-2 mb-2' disabled={store.selectedEvents().length === 0} onClick={removeSelection}>
                {store.translate( 'calendar.template.selection.actions.remove' )}
            </button>
            <button className='btn btn-primary mr-2 mb-2' disabled={store.selectedEvents().length === 0} onClick={modifySelection}>
                {store.translate( 'calendar.template.selection.actions.modify' )}
            </button>
        </div>
    </div>;
} );