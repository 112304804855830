import {applyPatch, types} from "mobx-state-tree";

export const EventsFilterModel = types.model( "RegionModel", {
    team: types.array( types.string ),
    region: types.array( types.string ),
    sources: types.array( types.string ),
    type: types.array( types.string ),
    user: types.array( types.string ),
    category: types.maybeNull( types.string ),
    booked: types.maybeNull( types.boolean )
} )
    .views( self => ({
        asQueryParams()
        {
            return Object.entries( self )
                .filter( ( [key, value] ) => self.hasOwnProperty( key ) )
                .map( ( [key, value] ) => {
                    if ( value != null ) {
                        let parameterValue = '';
                        if ( Array.isArray( value ) ) {
                            parameterValue = value.length > 0 ? value.join( "," ) : '';
                        }
                        else {
                            parameterValue = value;
                        }
                        return parameterValue === '' ? null : `${key}=${parameterValue}`;
                    }
                    return null;
                } )
                .filter( x => x != null )
                .join( '&' )
        }
    }) )
    .actions( self => ({
        updateValue( property: string, value: string[] )
        {
            switch ( property ) {
                case 'category':
                    self.category = value.length > 0 ? value[0] : null;
                    break;
                case 'booked':
                    self.booked = (value.length > 0 && value[0] !== '') ? value[0] === 'true' : null;
                    break;
                default:
                    if ( self.hasOwnProperty( property ) ) {
                        applyPatch( self, {op: 'replace', path: `/${property}`, value: value} );
                    }
            }
        }
    }) );