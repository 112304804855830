import {getSnapshot, types} from "mobx-state-tree";

export const EventUserModel = types.model( "EventUserModel", {
    firstName: types.maybeNull( types.string ), lastName: types.maybeNull( types.string ), initials: types.maybeNull( types.string )
} );

export const EventTeamModel = types.model( "EventTeamModel", {
    name: types.maybeNull( types.string ), electrician: types.maybeNull( EventUserModel ), initials: types.maybeNull( types.string )
} );

export const ContactModel = types.model( "ContactModel", {
    name: types.maybeNull( types.string ),
    firstName: types.maybeNull( types.string ),
    lastName: types.maybeNull( types.string ),
    city: types.maybeNull( types.string ),
    source: types.maybeNull( types.string )
} );

export const EventProjectModel = types.model( "EventProjectModel", {
    contact: types.maybeNull( ContactModel ),
    client: types.maybeNull( types.string ),
    location: types.maybeNull( types.string ),
    panelCount: types.maybeNull( types.number ),
    panelType: types.maybeNull( types.string ),
    roofType: types.maybeNull( types.string ), roofHeight: types.maybeNull( types.string ),
    invertorType: types.maybeNull( types.string ),
    status: types.maybeNull( types.string )
} );

export const CalendarExtendedPropsModel = types.model( "CalendarExtendedPropsModel", {
    sources: types.array( types.string ),
    region: types.maybeNull( types.string ),
    type: types.maybeNull( types.string ),
    description: types.maybeNull( types.string ),
    editable: types.boolean,
    selected: types.optional( types.boolean, false ),
    user: types.maybeNull( EventUserModel ),
    team: types.maybeNull( EventTeamModel ),
    project: types.maybeNull( EventProjectModel ), url: types.maybeNull( types.string ),
} );

export const CalendarEventModel = types.model( "CalendarEventModel", {
    id: types.identifier,
    editable: types.boolean,
    title: types.maybeNull( types.string ),
    start: types.string, end: types.string, overlap: types.optional( types.boolean, false ),
    extendedProps: CalendarExtendedPropsModel
} )
    .volatile( self => ({
        eventReference: null,
    }) )
    .actions( self => ({
        setEventReference( eventRef: any )
        {
            return self.eventReference = eventRef;
        },
    }) )
    .actions( self => ({
        setSelected( isSelected: boolean )
        {
            if ( self.extendedProps.editable ) {
                const previousSelectionState = self.extendedProps.selected;
                self.extendedProps.selected = isSelected;

                // keep the friggin calendar event in sync :(
                if ( previousSelectionState !== isSelected ) {
                    // @ts-ignore
                    self.eventReference?.setExtendedProp( 'selected', isSelected );
                }
            }
        }
    }) )
    .actions( self => ({
        toggleSelected()
        {
            if ( self.extendedProps.selected ) {
                self.setSelected( false );
            }
            else {
                self.setSelected( true );
            }
        }
    }) )
    .actions( self => ({
        remove()
        {
            // @ts-ignore
            self.eventReference?.remove();
        }, updateProps( modificationStateSnapshot: any )
        {
            if ( modificationStateSnapshot.sources ) {
                self.extendedProps.sources = modificationStateSnapshot.sources.map( ( s: any ) => s.value );
            }
            if ( modificationStateSnapshot.region ) {
                self.extendedProps.region = modificationStateSnapshot.region.value;
            }
            if ( modificationStateSnapshot.description != null ) {
                self.extendedProps.description = modificationStateSnapshot.description;

            }
            // update actual event via the calendar api if possible, so that a rerender is triggered
            const eventReference: any | null = self.eventReference;
            if ( eventReference != null ) {
                const snapshot = getSnapshot( self.extendedProps );
                Object.entries( snapshot )
                    .forEach( ( entry: any[] ) => {
                        eventReference.setExtendedProp( entry[0], entry[1] );
                    } )
            }
        }
    }) )
    .actions( self => ({
        updateStoredEvent( calendarEvent: any )
        {
            const asJson = calendarEvent.toPlainObject();
            if ( self.id !== asJson.id ) {
                self.id = asJson.id;
            }
            self.editable = asJson.extendedProps.editable;
            self.title = asJson.title;
            if ( asJson.start ) {
                self.start = calendarEvent.start.toISOString();
            }
            if ( asJson.end ) {
                self.end = calendarEvent.end.toISOString();
            }
            self.extendedProps.sources = asJson.extendedProps.sources.map( ( s: string ) => s ) || [];
            self.extendedProps.type = asJson.extendedProps.type;
            self.extendedProps.editable = asJson.extendedProps.editable || true;
            self.overlap = asJson.extendedProps.type === "INSPECTION";
            self.setSelected( self.extendedProps.selected );
        }
    }) );