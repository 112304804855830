import {types} from "mobx-state-tree";

export const ModalModel = types.model( "ModalModel", {
    id: types.identifier, title: types.maybe( types.string ), // body: types.maybe(<React.Fragment/>) todo ??,
    open: types.boolean
} )
    .volatile( self => ({
        body: null, callback: null, callbackExecuted: null
    }) )
    .actions( self => ({
        setBody( value: any )
        {
            self.body = value;
        }, setCallback( value: any )
        {
            self.callback = value;
        }, executeCallBack()
        {
            if ( self.callback ) {
                // @ts-ignore
                self.callbackExecuted = self.callback();
            }
        }
    }) )
    .actions( self => ({
        openModal( title: string, body: any, callback: any )
        {
            self.title = title;
            self.setBody( body );
            self.setCallback( callback );
            self.open = true;
        }, closeModal()
        {
            self.open = false;
        }
    }) );