export function unique( value: any, index: number, self: any[] )
{
    return self.indexOf( value ) === index;
}
function easterByYear(year: number){

    const a = year % 19;
    const b =Math.floor( year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const g = Math.floor(( 8 * b + 13 ) / 25);
    const h = ( 19 * a + b - d - g + 15 ) % 30;
    const j = Math.floor(c / 4);
    const k = c % 4;
    const m =Math.floor( ( a + 11 * h ) / 319);
    const r = ( 2 * e + 2 * j - k - h - m + 32 ) % 7;
    const n = Math.floor(( h - m + r + 90 ) / 25);
    const p = ( h - m + r + n + 19 ) % 32;

    return new Date(year,n-1,p);
}

export function holidays(year :number){
    let easter = easterByYear(year);
    let holidays:Date[] = [];
    holidays.push(new Date(easter.setDate(easter.getDate()+1)));
    holidays.push(new Date(easter.setDate(easter.getDate()+38)));
    holidays.push(new Date(easter.setDate(easter.getDate()+11)));
    holidays.push(new Date(year,0,1));
    holidays.push(new Date(year+1,0,1));
    holidays.push(new Date(year,4,1));
    holidays.push(new Date(year,6,21));
    holidays.push(new Date(year,7,15));
    holidays.push(new Date(year,10,1));
    holidays.push(new Date(year,10,11));
    holidays.push(new Date(year,11,25));

    return holidays;
}

