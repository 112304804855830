function convertToQueryParameters( parameters: { [p: string]: string } ): string
{
    return Object.entries( parameters )
        .map( ( key, value ) => `${key}=${value}` )
        .join( '&' );
}

function appendQueryParametersTo( baseUrl: string, parametersToAdd: string ): string
{
    if ( baseUrl.includes( '?' ) ) {
        return `${baseUrl}&${parametersToAdd}`;
    }
    else {
        return `${baseUrl}?${parametersToAdd}`;
    }
}

export function appendParametersToUrl( url: string, parameters: { [key: string]: string } ): string
{
    if ( !url ) {
        throw new Error( "An url is required to add parameters to" );
    }

    const baseUrl = url.trim();
    if ( baseUrl === '' ) {
        throw new Error( "Url may not be blank." );
    }

    const parametersToAdd = convertToQueryParameters( parameters );
    return appendQueryParametersTo( baseUrl, parametersToAdd );
}

export function appendQueryParametersToUrl( url: string, queryParameters: string ): string
{
    if ( !url ) {
        throw new Error( "An url is required to add parameters to" );
    }

    const baseUrl = url.trim();
    if ( baseUrl === '' ) {
        throw new Error( "Url may not be blank." );
    }

    return appendQueryParametersTo( baseUrl, queryParameters );
}