import * as React from "react";
import {observer} from "mobx-react-lite";
import {Modal} from "react-bootstrap";
import {useCalendar} from "../../models/calendar.store";

export const GlobalModal = observer( () => {
    const store = useCalendar();

    const handleClose = ( executeAction: boolean ) => {
        if ( executeAction ) {
            store.modal?.executeCallBack();
        }
        store.modal?.closeModal()
    };

    if ( !store.modal ) {
        return (null);
    }

    if ( !store.modal.open ) {
        return <div></div>;
    }

    return <Modal show={store.modal.open} onHide={() => handleClose( false )} centered>
        <Modal.Header closeButton>
            <Modal.Title>{store.modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {store.modal.body}
        </Modal.Body>
        <Modal.Footer>
            <button type='button' className="btn btn-secondary" onClick={() => handleClose( false )}>Close</button>
            <button type='button' className="btn btn-primary" onClick={() => handleClose( true )}>Confirm</button>
        </Modal.Footer>
    </Modal>
} );