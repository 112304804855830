import * as React from "react";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {BadgeComponentProps} from "./badge.component";

export interface TooltipProps
{
    id: string;
    content: any;
}

export const TooltipComponent = ( props: TooltipProps ) => {
    return <OverlayTrigger
        key='top'
        placement='top'

        overlay={<Tooltip id={props.id}>
            {props.content}
        </Tooltip>}
    >
        <i className='fas fa-question-circle'></i>
    </OverlayTrigger>;
};

export interface BadgeTooltipProps extends TooltipProps, BadgeComponentProps
{
}

export const BadgeTooltipComponent = ( props: BadgeTooltipProps ) => {
    const additionalCss = props.additionalCss ? props.additionalCss.join( ' ' ) : '';
    return <OverlayTrigger
        key='top'
        placement='top'
        overlay={<Tooltip id={props.id}>
            {props.content}
        </Tooltip>}
    >
        <span className={`badge badge-${props.badgeType} ${additionalCss}`}>{props.badgeContent}</span>
    </OverlayTrigger>;
};