import {cast, types} from "mobx-state-tree";
import {SourceModel} from "./source.model";
import {RegionModel} from "./region.model";

export const ModificationStateModel = types.model( "ModificationStateModel", {
    sources: types.array( SourceModel ), region: types.maybeNull( RegionModel ), description: types.maybeNull( types.string )
} )
    .actions( self => ({
        updateSources( sources: [{ label: string, value: string }] )
        {
            self.sources = cast( sources.map( ( x: any ) => {
                return {...x}
            } ) );
        }, clear()
        {
            self.sources = cast( [] );
            self.region = null;
            self.description = null;
        }, updateRegion( region: any )
        {
            if ( region != null ) {
                self.region = cast( {...region} );
            }
            else {
                self.region = null;
            }
        }, updateDescription( description: any )
        {
            if ( description != null ) {
                self.description = cast( description );
            }
            else {
                self.description = null;
            }
        }
    }) );
