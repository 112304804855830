import * as React from "react";
import {useCallback} from "react";
import {useCalendar} from "../../models/calendar.store";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import FullCalendar from "@fullcalendar/react";
import {observer} from "mobx-react-lite";
import {debounce} from "lodash";

interface BaseCalendarProps
{
    titleFormat?: any;
    dayHeaderFormat?: any;

    modifiable: boolean;

    initialView: string;
    // viewConfiguration: any;
    possibleViews: any;

    eventContentComponent: any;
    initialEvents: any;

    allowPeriodSelection: boolean;
    fetchEvents: any;
    initialDate?: any;
}

export const BaseCalendar = observer( ( props: BaseCalendarProps ) => {
    const store = useCalendar();

    const handleEventClick = ( info: any ) => {
        if ( props.modifiable ) {
            store.toggleSelected( info );
        }
    };

    const handleEventAdd = ( info: any ) => {
        // workaround to disable month view dropping
        if ( props.modifiable && info.view.type !== 'dayGridMonth' ) {
            store.updateEvent( info );
        }
    };

    const handleEventChange = function ( info: any ) {
        // workaround to disable month view dragging
        if ( info.event._context.viewApi.type === 'dayGridMonth' ) {
            const event = info.event.toPlainObject();
            const oldEvent = info.oldEvent.toPlainObject();
            if ( event.start !== oldEvent.start || event.end !== oldEvent.end ) {
                info.revert();
            }
        }
        else if ( props.modifiable ) {
            store.changeEvent( info.oldEvent, info.event );
        }
    };

    const handleEventRemove = ( info: any ) => {
        // ignore, remove currently handled by store
    };

    const activePlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin];
    const headerToolbar = {
        left: props.allowPeriodSelection ? 'prev,next today' : '', center: 'title', right: props.possibleViews ? props.possibleViews.join( ',' ) : ''
    };
    // todo convert enum to string
    const initialView = props.initialView;
    const isEditable = props.modifiable;
    const isSelectable = false;

    const handleDateSelect = ( info: any ) => {
        // ignore, is for when an event should be added by selecting a time range
    };

    const eventContent = props.eventContentComponent;
    const initialDate = props.initialDate;

    const eventOverlap = ( stillEvent: any, movingEvent: any ): boolean => {
        return stillEvent.overlap && movingEvent.overlap;
    };

    const eventTimeFormat = {
        hour: "2-digit", minute: "2-digit", omitZeroMinute: false, meridiem: false, hour12: false
    };
    const slotLabelFormat = {
        hour: '2-digit', minute: '2-digit', omitZeroMinute: false, meridiem: false, hour12: false
    };

    // todo should someday look into why this configuration doesn't work
    // const viewConfiguration = {
    //     dayGridMonth: {
    //         editable: false, droppable: false
    //     }, month: {
    //         editable: false, droppable: false
    //     }
    // };

    const handleEventClassNames = function ( ctx: any ) {
        const classNames = ['izen-calendar-event'];
        const extendedProps = ctx.event.extendedProps;
        if ( extendedProps.type ) {
            classNames.push( 'izen-calendar-event-' + extendedProps.type.toString().toLowerCase() )
        }
        if ( extendedProps.selected ) {
            classNames.push( 'selected-event' );
        }
        return classNames;
    };

    const fetchEvents = ( rangeInfo: any ) => {
        props.fetchEvents( rangeInfo.startStr, rangeInfo.endStr );
    };

    const fetchEventsHandler = useCallback( debounce( fetchEvents, 150 ), [] );

    return <FullCalendar
        timeZone={"Europe/Brussels"}
        allDaySlot={false}
        // views={viewConfiguration}
        plugins={activePlugins}
        expandRows={true}
        height={'100%'}
        themeSystem={'bootstrap'}
        headerToolbar={headerToolbar}
        initialView={initialView}
        titleFormat={props.titleFormat}
        eventTimeFormat={eventTimeFormat}
        editable={isEditable}
        selectable={isSelectable}
        selectMirror={isEditable}
        events={props.initialEvents}
        firstDay={1}
        eventOverlap={eventOverlap}
        droppable={isEditable}
        datesSet={fetchEventsHandler}
        hiddenDays={[0]}
        select={handleDateSelect}
        eventClassNames={handleEventClassNames}
        eventContent={eventContent}
        eventClick={handleEventClick}
        eventAdd={handleEventAdd}
        eventChange={handleEventChange}
        eventRemove={handleEventRemove}
        eventReceive={handleEventAdd}
        slotLabelFormat={slotLabelFormat}
        dayHeaderFormat={props.dayHeaderFormat}
        fixedWeekCount={false}
        slotMinTime='08:00:00'
        slotMaxTime='21:00:00'
        initialDate={initialDate}
    />;
} );