import * as React from "react";
import {useEffect, useState} from "react";
import {BaseCalendar} from "./components/calendar/calendar-base";
import {CalendarDraggableEvents} from "./components/calendar/calendar-draggable-events";
import {CalendarSelectionManager} from "./components/calendar/calendar-selection-manager";
import {useCalendar} from "./models/calendar.store";
import Select, {components, ValueType} from 'react-select';
import {getSnapshot} from "mobx-state-tree";
import {observer} from "mobx-react-lite";

function eventContentComponent( store: any )
{

    return ( info: any ) => {
        const sources = store.configuration.sourcesForEvent( info.event.extendedProps.sources );
        const sourceLabels = sources && Array.isArray( sources ) ? sources.map( ( s: any ) => s.label ).join( ', ' ) : '';
        return (<React.Fragment>
            <div>
                <b className='pr-2'>{info.timeText}</b>
                <i>{info.event.title}</i>
            </div>
            <div className="d-flex flex-column text-truncate">
                {sourceLabels.length > 0 && <i title={sourceLabels}>Sources: {sourceLabels}</i>}
            </div>
        </React.Fragment>)
    };
}

const IndicatorsContainer = ( props: any ) => {
    const selectAll = () => {
        props.setValue( props.options )
    };
    return (<div className="d-flex flex-row">
        <div className={`select-all ${props.getValue()?.length === props.options.length ? 'd-none' : ''}`} onClick={selectAll}>
            <i className="fas fa-check-double"></i></div>
        <components.IndicatorsContainer {...props} />
    </div>);
};

const SourcesSelection = observer( () => {
    const store = useCalendar();
    const sourceOptions = store.configuration.sourceOptions();
    const [menuOpen, setMenuOpen] = React.useState( undefined );
    const [selected, setSelected] = useState<ValueType<any, true>>();

    useEffect( () => {
        const selectedEvents = store.selectedEvents();
        if ( selectedEvents.length === 1 ) {
            setSelected( store.configuration.sourcesForEvent( getSnapshot( selectedEvents[0].extendedProps.sources ) ) );
        }
    }, [] );

    const onMenuOpen = () => {
        if ( menuOpen !== undefined ) {
            setMenuOpen( undefined );
        }
    };

    const updateSelection = ( selectedOptions: any ) => {
        const optionsToSelect = (Array.isArray( selectedOptions ) && selectedOptions.length === 1 && Array.isArray(
            selectedOptions[0] )) ? selectedOptions[0] : selectedOptions;
        setSelected( optionsToSelect );
        store.modificationState.updateSources( optionsToSelect );
        if ( sourceOptions.length === optionsToSelect.length ) {
            // @ts-ignore
            setMenuOpen( false );
        }
    };
    return (<div className='form-group'>
        <label>{store.translate( 'calendar.template.properties.sources.label' )}</label>
        <Select closeMenuOnSelect={false}
                components={{IndicatorsContainer}}
                defaultValue={[]}
                isMulti
                onChange={updateSelection}
                value={selected}
                options={sourceOptions}
                onMenuOpen={onMenuOpen}
                menuIsOpen={menuOpen}
        />
    </div>);
} );

// https://react-select.com/components#replaceable-components
function TemplateModifySelection()
{
    return (<form className='modify-selection'>
        <SourcesSelection/>
    </form>)
}

export const UserTemplateCalendar = observer( ( props: any ) => {
    const store = useCalendar();
    React.useEffect( () => {
        store.initialize( {modifiable: true, ...props.configuration} );
    }, [store] );

    const fetchEvents = function ( from: string, to: string ) {
    };

    const save = () => {
        store.save();
    };
    const backToOverview = () => {
        window.location.href = store.configuration.backUrl;
    };

    const titleFormat = function () {
        return "";
    };

    const dayHeaderFormat = {
        weekday: 'long'
    };

    return (<div className='calendar-wrapper w-100 pt-3 pb-3 d-flex'>
        <div className='col-md-10'>
            <BaseCalendar titleFormat={titleFormat}
                          initialView={'timeGridWeek'}
                          possibleViews={[]}
                          allowPeriodSelection={false}
                          eventContentComponent={eventContentComponent( store )}
                          initialEvents={store.events.slice()}
                          fetchEvents={fetchEvents}
                          dayHeaderFormat={dayHeaderFormat}
                          modifiable={store.configuration.isModifiable()}/>
        </div>
        <div className='izen-calendar-sidebar col-md-2'>
            <div className='d-flex flex-column h-100'>
                <CalendarDraggableEvents types={props.configuration.appointmentTypes}/>
                <CalendarSelectionManager modifySelectionContent={() => <TemplateModifySelection/>}/>
            </div>
            <div className='izen-calendar-actions'>
                <a className='btn btn-link' onClick={backToOverview} href="#">
                    {store.translate( 'calendar.template.actions.back' )}
                </a>
                <button className='btn btn-primary' onClick={save} disabled={store.eventsToRemove.length === 0 && store.eventsToUpdate.length === 0}>
                    {store.translate( 'calendar.template.actions.save' )}
                </button>
            </div>
        </div>
    </div>);
} );
