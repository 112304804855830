import * as React from 'react';
import {flow, getSnapshot, types} from "mobx-state-tree";
import {ModalModel} from "./modal.model";
import {CalendarEventModel} from "./calendar-event.model";
import {ModificationStateModel} from "./modification-state.model";
import {getCookie} from "../../../helpers/CookieHelper";
import {CalendarConfigurationModel} from "./calendar-configuration.model";
import {unique} from "../../../helpers/utilities";
import {TimeSlotType} from "../types/time-slot-type";
import {EventsFilterModel} from "./events-filter.model";
import {registerCurrentUrlToBrowserHistory} from "../calendar-general";

interface EventExtendedPropsDto
{
    type: string,
    sources: string[],
    editable: boolean
}

interface EventDto
{
    id: string,
    title: string | null,
    extendedProps: EventExtendedPropsDto,
    start: string,
    end: string,
    overlap: boolean,
    editable: boolean
}

function createDefaultCalendarEventModelFor( calendarEvent: any )
{
    const asJson = calendarEvent.toPlainObject();
    const extendedProps = asJson.extendedProps;
    const mapped = {
        id: asJson.id, title: asJson.title, start: asJson.start, end: asJson.end, extendedProps: {
            type: extendedProps.type.toString(), sources: extendedProps.sources.map( ( s: any ) => {
                return s
            } ), editable: asJson.extendedProps.editable
        }, editable: asJson.extendedProps.editable, overlap: extendedProps.type.toString() === 'INSPECTION'
    };
    return mapped;
}

function matchesId( id: any )
{
    return ( x: any ) => x.id === id;
}

export const LastFetchModel = types.model( "LastFetchModel", {
    from: types.string, to: types.string
} );

export const CalendarStore = types
    .model( "CalendarStore", {
        eventsFilter: EventsFilterModel,
        configuration: CalendarConfigurationModel,
        modal: types.maybeNull( ModalModel ),
        modificationState: ModificationStateModel,
        lastFetch: LastFetchModel,
        events: types.array( CalendarEventModel ),
        eventsToRemove: types.array( CalendarEventModel ),
        eventsToUpdate: types.array( CalendarEventModel )
    } )
    .views( self => ({
        translate( code: string ): string
        {
            if ( self.configuration.translations.has( code ) ) {
                return (self.configuration.translations.get( code ) as any).value;
            }
            return code;
        }, selectedEvents()
        {
            return self.events.filter( e => e.extendedProps.selected === true );
        }
    }) )
    .views( self => ({
        uniqueSelectedEventTypes()
        {
            return self.selectedEvents()
                .map( ( se: any ) => se.extendedProps.type )
                .filter( unique )
        }
    }) )
    .actions( self => ({
        clearSelection()
        {
            self.selectedEvents().forEach( event => event.setSelected( false ) );
        }, openModal( title: string, body: any, callback: any )
        {
            if ( !self.modal ) {
                self.modal = ModalModel.create( {id: 'globalModal', open: false} );
            }
            self.modal.openModal( title, body, callback );
        }, updateEvent( addEvent: any )
        {
            if ( !addEvent ) {
                console.error( 'received update event without data' );
            }
            else {
                const event = addEvent.event;
                const existingEvent = self.events.find( matchesId( event.id ) );

                if ( !existingEvent ) {
                    const eventModel = CalendarEventModel.create( createDefaultCalendarEventModelFor( event ) );
                    eventModel.setEventReference( addEvent.event );
                    eventModel.updateStoredEvent( event );
                    self.events.push( eventModel );
                    /*
                    * If the event didn't exist yet, it was dragged onto the calendar.
                    * Dragged events are created and managed separately on the calendar, which means if we simply add it to our list,
                    * the event will be present twice, hence why we delete the event that has been added by the calendar.
                    */
                    event.remove();
                }
                else {
                    existingEvent.setEventReference( addEvent.event );
                    existingEvent.updateStoredEvent( event );
                }

                const refreshExistingEvent = self.events.find( matchesId( event.id ) );
                if ( refreshExistingEvent ) {
                    const markedAsToUpdate = self.eventsToUpdate.find( matchesId( event.id ) );
                    if ( markedAsToUpdate ) {
                        self.eventsToUpdate.remove( markedAsToUpdate );
                    }
                    const recreated = CalendarEventModel.create( getSnapshot( refreshExistingEvent ) );
                    self.eventsToUpdate.push( recreated );
                }
            }

        }, removeEvent( toRemove: any )
        {
            self.eventsToRemove.push( getSnapshot( toRemove ) );
            self.events.remove( toRemove );
            toRemove.remove();
        }, clearChanges()
        {
            self.eventsToUpdate.clear();
            self.eventsToRemove.clear();
        }, setEvents( toSet: any )
        {
            self.events = toSet.map( ( eventDto: EventDto ) => {
                eventDto.extendedProps.editable = eventDto.editable;
                eventDto.overlap = eventDto.extendedProps.type.toString() === TimeSlotType.INSPECTION;
                return eventDto;
            } );
        }
    }) )
    .actions( self => ({
        performFetch: flow( function* () {
            document.getElementById( "calendar-loading" )?.classList.remove( 'd-none' );
            const additionalFilter = self.eventsFilter.asQueryParams();

            const url = `${self.configuration.fetchEventsUrl}?from=${self.lastFetch.from}&to=${self.lastFetch.to}`;

            console.time( 'Request fetch' );
            const fetched = yield fetch( additionalFilter === '' ? url : `${url}&${additionalFilter}` )
                .then( resp => resp.json().then( ( json ) => {
                    registerCurrentUrlToBrowserHistory( self )
                    console.timeEnd( 'Request fetch' );
                    return json;
                } ) )
                .catch( resp => {
                    toastr.error( self.translate( "calendar.error.general" ) );
                    document.getElementById( "calendar-loading" )?.classList.add( 'd-none' );
                } );

            self.clearSelection();
            self.setEvents( fetched );
            document.getElementById( "calendar-loading" )?.classList.add( 'd-none' );
        } )
    }) )
    .actions( self => ({
        initializeFiltersInBatch( batchObject: any )
        {
            for ( let [key, value] of Object.entries( batchObject ) ) {
                // @ts-ignore
                self.eventsFilter.updateValue( key, value );
            }
        },
    }) )
    .actions( self => ({
        fetchEvents: flow( function* ( from: any, to: any ) {
            const startDate = from.split( 'T' )[0];
            const endDate = to.split( 'T' )[0];
            if ( self.configuration.fetchEventsUrl && (self.lastFetch.from != startDate || self.lastFetch.to != endDate) ) {
                console.time( 'Calendar fetch' );
                self.lastFetch.from = startDate;
                self.lastFetch.to = endDate;
                console.log( "startDate", self.lastFetch.from, "endDate", self.lastFetch.to );
                yield self.performFetch();
                console.timeEnd( 'Calendar fetch' );
            }
        } ),
        updateFilter: flow( function* ( property: string, value: any[] ) {
            console.time( 'Filter fetch' );
            self.eventsFilter.updateValue( property, value );
            yield self.performFetch();
            console.timeEnd( 'Filter fetch' );
        } ),
        updateFilterBatch: flow( function* ( batchObject: any ) {
            console.time( 'Filter fetch batch' );
            self.initializeFiltersInBatch( batchObject );
            yield self.performFetch();
            console.timeEnd( 'Filter fetch batch' );
        } ),
    }) )
    // calendar actions
    .actions( self => ({
        initialize( configuration: any )
        {
            Object.entries( configuration.translations )
                .map( ( entry: any[] ) => {
                    return {key: entry[0], value: entry[1]}
                } )
                .forEach( obj => self.configuration.translations.put( obj ) );
            self.configuration.sources = configuration.sources
                ?.map( ( obj: { id: string, name: string } ) => {
                    return {value: obj.id, label: obj.name}
                } );
            self.configuration.regions = configuration.regions
                ?.map( ( obj: { id: string, name: string } ) => {
                    return {value: obj.id, label: obj.name}
                } );
            self.configuration.modifiable = configuration.modifiable;
            self.configuration.fetchEventsUrl = configuration.fetchEventsUrl;
            self.configuration.saveUrl = configuration.saveUrl;
            self.configuration.backUrl = configuration.backUrl;
            if ( configuration.existingSlots ) {
                self.setEvents( configuration.existingSlots );
            }
            if ( configuration.initialEventsFrom ) {
                self.lastFetch.from = configuration.initialEventsFrom;
            }
            if ( configuration.initialEventsTo ) {
                self.lastFetch.to = configuration.initialEventsTo;
            }
        }, toggleSelected( clickEvent: any )
        {
            const event = clickEvent.event;
            const found = self.events.find( matchesId( event.id ) );
            const uniqueSelectedEventTypes = self.uniqueSelectedEventTypes();
            const containsUnavailableEvents = uniqueSelectedEventTypes
                .findIndex( item => item === TimeSlotType.UNAVAILABLE ) !== -1;

            let isSelectable = false;
            if ( containsUnavailableEvents ) {
                if ( event.extendedProps.type === TimeSlotType.UNAVAILABLE ) {
                    isSelectable = true;
                }
                else {
                    toastr.error( self.translate( 'calendar.agenda.modification.absence.notAllowed' ), null, {preventDuplicates: true} )
                }
            }
            else if ( uniqueSelectedEventTypes.length > 0 ) {
                if ( event.extendedProps.type !== TimeSlotType.UNAVAILABLE ) {
                    isSelectable = true;
                }
                else {
                    toastr.error( self.translate( 'calendar.agenda.modification.absence.notAllowed' ), null, {preventDuplicates: true} );
                }
            }
            else {
                isSelectable = true;
            }

            if ( isSelectable ) {
                if ( found ) {
                    found.setEventReference( event );

                    found.toggleSelected();
                }
            }

        },
        changeEvent( oldEvent: any, newEvent: any )
        {
            // check if id is present on the new event, if not, this means we are probably an extended property
            self.updateEvent( {event: newEvent._instance ? newEvent : oldEvent} );
        },
        modifySelection()
        {
            self.selectedEvents().forEach( se => {
                se.updateProps( getSnapshot( self.modificationState ) );
            } );
            self.clearSelection();
            self.modificationState.clear();
        }, removeSelection()
        {
            self.selectedEvents().forEach( event => {
                self.removeEvent( event );
            } );
        }, save()
        {
            // remove updates for events that should be deleted
            self.eventsToRemove.forEach( etr => {
                const found = self.eventsToUpdate.find( matchesId( etr.id ) );
                if ( found ) {
                    self.eventsToUpdate.remove( found );
                }
            } );

            const body = {
                eventsToRemove: getSnapshot( self.eventsToRemove ), eventsToUpdate: getSnapshot( self.eventsToUpdate )
            };

            fetch( self.configuration.saveUrl, {
                method: "POST", headers: {"Content-type": "application/json", "X-XSRF-Token": getCookie( "XSRF-TOKEN" ) as string}, body: JSON.stringify( body )
            } )
                .then( resp => {
                    const toastr = window.toastr;
                    if ( resp.ok ) {
                        self.clearChanges();
                        if ( toastr ) {
                            toastr.success( self.translate( 'calendar.feedback.save.success' ) )
                        }
                    }
                    else if ( toastr ) {
                        toastr.error( self.translate( 'calendar.feedback.save.error' ) )
                    }
                } );
        },
    }) );

// singleton usage
const calendarStore = CalendarStore.create( {
                                                configuration: {
                                                    modifiable: false, fetchEventsUrl: '', saveUrl: '', backUrl: ''
                                                },
                                                lastFetch: {from: '', to: ''},
                                                eventsToRemove: [],
                                                eventsToUpdate: [],
                                                eventsFilter: EventsFilterModel.create(),
                                                modal: null,
                                                modificationState: ModificationStateModel.create( {} )
                                            } );
const CalendarContext = React.createContext( calendarStore );

export function useCalendar()
{
    const store = React.useContext( CalendarContext );
    return store;
}
