import * as React from "react";

export interface BadgeComponentProps
{
    badgeContent: any;
    badgeType: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
    additionalCss?: string[];
}

export const BadgeComponent = ( props: BadgeComponentProps ) => {
    const additionalCss = props.additionalCss ? props.additionalCss.join( ' ' ) : '';
    return <span className={`badge badge-${props.badgeType} ${additionalCss}`}>{props.badgeContent}</span>;
};