import * as React from "react";
import {useState} from "react";
import {BaseCalendar} from "./components/calendar/calendar-base";
import {useCalendar} from "./models/calendar.store";
import {observer} from "mobx-react-lite";
import {BadgeTooltipComponent} from "../../components/form/tooltip.component";
import {BadgeComponent} from "../../components/form/badge.component";
import {TimeSlotType} from "./types/time-slot-type";
import {appendQueryParametersToUrl} from "../../helpers/request-utils";
import {holidays} from "../../helpers/utilities";

function projectInfoItem( label: any, value: any )
{
    return <div className='row m-0'><span className='col-4 pr-2'>{label}</span> <span className='col-8'>{value}</span></div>
}

function eventTypeShortName( eventType: string ): string
{
    const lowerCased = eventType.toLowerCase();
    const shortName = lowerCased.slice( 0, 3 );

    if ( lowerCased.startsWith( "advice" ) ) {
        return lowerCased.endsWith( "home" ) ? `${shortName}h` : `${shortName}o`
    }
    return shortName;
}

export function registerCurrentUrlToBrowserHistory( store: any )
{
    const baseUrl = window.location.href.split( '?' )[0];
    const urlToCurrentPage = appendQueryParametersToUrl( baseUrl, store.eventsFilter.asQueryParams() );
    window.history.pushState( {}, 'Calendar overview', urlToCurrentPage );
}

function registerCurrentUrlToBrowserOnClick( store: any )
{
    return ( _: any ) => {
        registerCurrentUrlToBrowserHistory( store );
    };
}

function eventContentComponent( store: any )
{
    return ( info: any ) => {
        const eventProps = info.event.extendedProps;
        const badgeContent = <span className='text-monospace'>{eventProps.user.initials}</span>;

        let badgeType: any = 'dark';
        const project = eventProps.project;
        if ( project ) {
            const projectStatus = project.status;
            switch ( projectStatus ) {
                case 'confirmed':
                    badgeType = 'success';
                    break;
                case 'requested':
                    badgeType = 'warning';
                    break;
            }

            const badgeTooltip = <React.Fragment>
                {eventProps.team && projectInfoItem( store.translate( 'calendar.projectInfo.team' ), eventProps.team.name )}
                {eventProps.team && eventProps.team.electrician && projectInfoItem( store.translate( 'Electrician' ),
                                                                                    `${eventProps.team.electrician.firstName} ${eventProps.team.electrician.lastName}` )}
                {project.contact && projectInfoItem( store.translate( 'calendar.projectInfo.clientSource' ), project.contact.source )}
                {project.contact && projectInfoItem( store.translate( 'calendar.projectInfo.client' ), project.contact.name )}
                {project.location && projectInfoItem( store.translate( 'calendar.projectInfo.location' ), project.location )}
                {project.panelType && projectInfoItem( store.translate( 'calendar.projectInfo.panel' ), `${project.panelCount} x ${project.panelType}` )}
                {project.invertorType && projectInfoItem( store.translate( 'calendar.projectInfo.invertor' ), project.invertorType )}
                {project.roofType && projectInfoItem( store.translate( 'calendar.projectInfo.roof' ),
                                                      `${project.roofType} ${project.roofHeight != null ? `- ${project.roofHeight}` : ''}` )}
            </React.Fragment>;

            const generalEventInfo = <React.Fragment>
                <span className='ml-2'>({eventTypeShortName( eventProps.type )})</span>
                <span className='ml-2'>{info.timeText}</span>
                <span className='ml-2'>{`${eventProps.project.location ?? ""} ${project.contact.lastName}`}</span>
            </React.Fragment>;

            const generalInfo = eventProps.url
                ? <a className='navigate-to-booking'
                     href={eventProps.url}
                     onClick={registerCurrentUrlToBrowserOnClick( store )}>{generalEventInfo}</a>
                : generalEventInfo;

            return <React.Fragment>
                <div className='text-truncate'>
                    <BadgeTooltipComponent badgeContent={badgeContent}
                                           badgeType={badgeType}
                                           id={`user${info.event.id}`}
                                           content={badgeTooltip}
                                           additionalCss={['ml-1', 'p-1']}/>
                    {generalInfo}
                </div>
            </React.Fragment>;
        }

        const isNationalHoliday = eventProps.type === TimeSlotType.NATIONAL_HOLIDAY;
        const isUnavailable = eventProps.type === TimeSlotType.UNAVAILABLE;
        const isAbsent = isUnavailable || isNationalHoliday;

        const region = eventProps.region && eventProps.region.length > 0
            ? store.configuration.regionForEvent( eventProps.region ).label
            : "";

        const generalEventInfo = <React.Fragment>
            {!isNationalHoliday && <span className='ml-2'>({eventTypeShortName( eventProps.type )})</span>}
            {isNationalHoliday && <span className='ml-2'>{store.translate( 'MyIzenApplicationModule.entities.enums.TimeSlotType.NATIONAL_HOLIDAY' )}</span>}
            {!isNationalHoliday && <span className='ml-2'>{info.timeText}</span>}
            {!isAbsent && <span className='ml-2'>{region}</span>}
            {isAbsent && <span className='ml-2'>{eventProps.description}</span>}
        </React.Fragment>;

        const generalInfo = eventProps.url
            ? <a className='navigate-to-booking'
                 href={eventProps.url}
                 onClick={registerCurrentUrlToBrowserOnClick( store )}>{generalEventInfo}</a>
            : generalEventInfo;

        return <React.Fragment>
            <div className='text-truncate'>
                <BadgeComponent badgeContent={badgeContent} badgeType={badgeType} additionalCss={['ml-1', 'p-1']}/>
                {generalInfo}
            </div>
        </React.Fragment>;
        // }
    };
}

export const GeneralCalendar = observer( ( props: any ) => {
    const [initialDate, setInitialDate] = useState( undefined as any );
    const store = useCalendar();
    React.useEffect( () => {
        store.initialize( {modifiable: false, ...props.configuration} );
    }, [store] );

    React.useEffect( () => {
        $( ".calendar-filter-configuration" )
            .on( 'bootstrapui.change', ( changeEvent, adapter ) => {
                const property = $( adapter.getTarget() ).data( 'entity-query-property' );
                const value = adapter.getValue().map( ( av: any ) => av.value );
                store.updateFilter( property, value );
            } );
    }, [] );

    React.useEffect( () => {
        // @ts-ignore
        const activeFilters: any = window.getAllQueryFilterValues();
        store.initializeFiltersInBatch( activeFilters );
    }, [] );

    const fetchEvents = function ( from: string, to: string ) {

        const dates = holidays( parseInt( from.substr( 0, 4 ) ) );
        dates.forEach( function ( p1: Date, p2: number, p3: Date[] ) {
            let date = p1.getFullYear() + '-' + (p1.getMonth() + 1).toString().padStart( 2, "0" ) + '-' + p1.getDate().toString().padStart( 2, "0" );
            const querySelector = document.querySelector( '[data-date="' + date + '"]' );
            if ( querySelector ) {
                querySelector.classList.add( "bg-holiday" );
            }
        } )
        store.fetchEvents( from, to );
        return store.events.slice();
    };

    const dayHeaderFormat = {
        weekday: 'long'
    };

    React.useEffect( () => {
        const urlSearchParams = new URLSearchParams( window.location.search );
        setInitialDate( urlSearchParams.get( "from" ) as any )
    }, [] );

    return (<div className='calendar-wrapper w-100 pt-3 pb-3 d-flex'>
        <div className='col-md-12'>
            <BaseCalendar initialView={'dayGridMonth'}
                          initialDate={initialDate}
                          possibleViews={[]}
                          allowPeriodSelection={true}
                          eventContentComponent={eventContentComponent( store )}
                          initialEvents={store.events.slice()}
                          fetchEvents={fetchEvents}
                          dayHeaderFormat={dayHeaderFormat}
                          modifiable={store.configuration.isModifiable()}/>
        </div>
    </div>);
} )

