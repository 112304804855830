import {getSnapshot, types} from "mobx-state-tree";
import {RegionModel} from "./region.model";
import {SourceModel} from "./source.model";

export const TranslationModel = types.model( "TranslationModel", {
    key: types.identifier, value: types.string
} );

export const CalendarConfigurationModel = types.model( "CalendarConfigurationModel", {
    translations: types.map( TranslationModel ),
    modifiable: types.boolean,
    sources: types.array( SourceModel ),
    regions: types.array( RegionModel ),
    fetchEventsUrl: types.maybeNull( types.string ),
    saveUrl: types.string,
    backUrl: types.string
} )
    .views( self => ({
        sourceOptions()
        {
            return getSnapshot( self.sources );
        }, regionOptions()
        {
            return getSnapshot( self.regions );
        }, isModifiable()
        {
            return self.modifiable;
        }, sourcesForEvent( eventSources: string[] )
        {
            return [...self.sources.filter( ( source: { label: string, value: string } ) => {
                return eventSources?.findIndex( ( s: string ) => s === source.value ) !== -1;
            } ).map( ( x: any ) => {
                return {...x}
            } )];
        }, regionForEvent( eventRegion: string )
        {
            return self.regions.filter( ( region: { label: string, value: string } ) => {
                return region.value === eventRegion;
            } ).map( ( x: any ) => {
                return {...x}
            } )[0];
        }
    }) );