import {ValueType} from "react-select";
import {observer} from "mobx-react-lite";
import {useCalendar} from "../../../models/calendar.store";
import {default as React, useEffect, useState} from "react";
import {SelectComponent} from "../../../../../components/form/select.component";
import {unique} from "../../../../../helpers/utilities";

export const ModificationRegion = observer( () => {
    const store = useCalendar();

    const [selected, setSelected] = useState<ValueType<any, true>>();

    useEffect( () => {
        const selectedEvents = store.selectedEvents();
        let regionToSelect;
        if ( selectedEvents.length === 1 && selectedEvents[0].extendedProps.region ) {
            regionToSelect = selectedEvents[0].extendedProps.region;
        }
        if ( selectedEvents.length > 1 ) {
            const selectedRegions = selectedEvents.map( se => se.extendedProps.region )
                .filter( unique );
            if ( selectedRegions.length === 1 && selectedRegions[0] != null ) {
                regionToSelect = selectedRegions[0];
            }
        }
        if ( regionToSelect ) {
            const regionForEvent: any = store.configuration.regionForEvent( regionToSelect );
            setSelected( regionForEvent );
            store.modificationState.updateRegion( regionForEvent );
        }
    }, [] );

    const onSelectionChange = ( selectedOptions: any ) => {
        store.modificationState.updateRegion( selectedOptions );
        setSelected( selectedOptions );
    };

    return <SelectComponent label={store.translate( 'calendar.agenda.properties.region.label' )}
                            selectableOptions={store.configuration.regionOptions()}
                            selected={selected}
                            multiValue={false}
                            onChange={onSelectionChange}/>;
} );