import * as React from "react";
import Select, {components, OptionsType} from "react-select";
import { SelectComponentProps } from "types/types";

const IndicatorsContainer = ( props: any ) => {
    const selectAll = () => {
        props.setValue( props.options )
    };
    return (<div className="d-flex flex-row">
        <div className={`select-all ${props.getValue()?.length === props.options.length ? 'd-none' : ''}`} onClick={selectAll}>
            <i className="fas fa-check-double"></i></div>
        <components.IndicatorsContainer {...props} />
    </div>);
};

export const SelectComponent = ( props: SelectComponentProps ) => {
    const selectableOptions = props.selectableOptions;
    const [menuOpen, setMenuOpen] = React.useState( undefined );

    const onMenuOpen = () => {
        if ( menuOpen !== undefined ) {
            setMenuOpen( undefined );
        }
    };

    const updateSelection = ( selectedOptions: any ) => {
        const optionsToSelect = (Array.isArray( selectedOptions ) && selectedOptions.length === 1 && Array.isArray(
            selectedOptions[0] )) ? selectedOptions[0] : selectedOptions;

        props.onChange( optionsToSelect );

        if ( selectableOptions.length === optionsToSelect.length ) {
            // @ts-ignore
            setMenuOpen( false );
        }
    };

    let selectConfiguration = {
        closeMenuOnSelect: !props.multiValue,
        defaultValue: [],
        onChange: updateSelection,
        value: props.selected,
        options: selectableOptions,
        onMenuOpen: onMenuOpen,
        menuIsOpen: menuOpen
    };

    let selectComponent;
    if ( props.multiValue ) {
        selectComponent = <Select {...selectConfiguration} isMulti components={{IndicatorsContainer}}/>
    }
    else {
        selectComponent = <Select {...selectConfiguration}/>
    }

    return <div className='form-group'>
        <label>{props.label}</label>
        {selectComponent}
    </div>;
};


