import {default as React, useEffect} from "react";
import {Draggable} from "@fullcalendar/interaction";
import {v4 as uuidv4} from "uuid";
import {useCalendar} from "../../models/calendar.store";
import {TimeSlotType} from "../../types/time-slot-type";

export function CalendarDraggableEvent( props: { draggableEvent: any } )
{
    const typeCssClass = 'izen-calendar-event-' + props.draggableEvent.type.toLowerCase();
    const cssClasses = 'mb-2 fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event izen-calendar-event ' + typeCssClass;

    const wrapperProps = {
        className: cssClasses, title: props.draggableEvent.title, type: props.draggableEvent.type, duration: props.draggableEvent.duration
    };
    const hourIndicator = props.draggableEvent.durationHours > 0 ? `${props.draggableEvent.durationHours}h` : '';
    const minutesIndicator = props.draggableEvent.durationMinutes > 0 ? `${props.draggableEvent.durationMinutes}m` : '';
    return (<div {...wrapperProps}>
        <div className='fc-event-main izen-calendar-event-content'>
            {props.draggableEvent.title} ({hourIndicator}{minutesIndicator})
        </div>
    </div>)
}

export function CalendarDraggableEvents( props: { types: any[] } )
{
    const store = useCalendar();
    const shouldNotRender = !props.types || props.types.length === 0;
    useEffect( () => {
        if ( !shouldNotRender ) {
            let draggableEl: HTMLElement | null = document.getElementById( "draggableEvents" );
            if ( draggableEl ) {
                new Draggable( draggableEl, {
                    itemSelector: '.fc-event', eventData: function ( eventEl: any ) {
                        const eventType = eventEl.getAttribute( 'type' );
                        return {
                            id: uuidv4(),
                            title: eventEl.getAttribute( 'title' ),
                            duration: eventEl.getAttribute( 'duration' ),
                            overlap: eventType === TimeSlotType.INSPECTION,
                            extendedProps: {
                                type: eventType, editable: true, sources: []
                            },
                            classNames: ['izen-calendar-event', 'izen-calendar-event-' + eventType.toLowerCase()]
                        };
                    }
                } );
            }
        }
    }, [] );

    if ( shouldNotRender ) {
        return (null);
    }

    return <div id='draggableEvents' className='izen-calendar-events-container'>
        <h4>{store.translate( 'calendar.template.appointmentTypes.header' )}</h4>
        <div>{props.types.map( event => <CalendarDraggableEvent key={event.type} draggableEvent={event}/> )}</div>
    </div>;
}