import {default as React, useCallback, useEffect, useState} from "react";
import {useCalendar} from "../../../models/calendar.store";
import {TextAreaComponent} from "../../../../../components/form/textArea.component";
import {debounce} from "lodash";
import {unique} from "../../../../../helpers/utilities";

export const ModificationDescription = () => {
    const store = useCalendar();

    const [selected, setSelected] = useState<string>();

    useEffect( () => {
        const selectedEvents = store.selectedEvents();
        if ( selectedEvents.length === 1 ) {
            let existingDescription = selectedEvents[0].extendedProps.description;
            setSelected( existingDescription as string );
            store.modificationState.updateDescription( existingDescription as string );
        }
        if ( selectedEvents.length > 1 ) {
            const uniqueDescriptions = selectedEvents.map( se => se.extendedProps.description )
                .filter( unique );
            if ( uniqueDescriptions.length === 1 ) {
                setSelected( uniqueDescriptions[0] as string );
                store.modificationState.updateDescription( uniqueDescriptions[0] as string );
            }
        }
    }, [] );

    const updateState = ( description: string ) => {
        store.modificationState.updateDescription( description );
    };

    const handler = useCallback( debounce( updateState, 200 ), [] );

    const onChange = ( event: any ) => {
        if ( event.target ) {
            const value = event.target.value || '';
            setSelected( value );
            handler( value );
        }
    };

    return <TextAreaComponent label={store.translate( 'calendar.agenda.properties.description.label' )}
                              currentValue={selected}
                              onChange={onChange}/>;
};