// eslint-disable
import './calendar-vdom'
import * as React from "react";
import ReactDOM from 'react-dom';
import {UserTemplateCalendar} from "./calendar-template-user";
import {GlobalModal} from "./components/modal/modal";
import {UserAgendaCalendar} from "./calendar-agenda-user";
import {GeneralCalendar} from "./calendar-general";

function calendarConfiguration( element: HTMLElement )
{
    return JSON.parse( element.dataset.calendarconfiguration as string );
}

function component( content: any )
{
    return <React.Fragment>
        {content}
        <GlobalModal/>
        <div id='calendar-loading' className='d-none'>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
        </div>
    </React.Fragment>;
}

$( document ).ready( function () {
    document.getElementsByTagName( "body" )[0]?.classList.add( "calendar-view" );

    const supported = [{id: "calendar-template-user", type: UserTemplateCalendar}, {id: "calendar-agenda-user", type: UserAgendaCalendar},
        {id: "calendar-general", type: GeneralCalendar}];

    supported.forEach( item => {
        const element = document.getElementById( item.id );
        if ( element ) {
            ReactDOM.render( component( <item.type configuration={calendarConfiguration( element )}/> ), element );
        }
    } );
} );
